.App {
  background-color: white;
}

.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.Auth-form {
  width: 420px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 8px;
  background-color: white;
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.Auth-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: rgb(34, 34, 34);
  font-weight: 800;
}

label {
  font-size: 14px;
  font-weight: 600;
  color: rgb(34, 34, 34);
}
#be{
  color:rgb(7, 142, 206);
  font-weight: bolder;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-size: 14pt;
  align-items: center;
  align-content: center;
  text-align: center;
}
#logo{
  margin-right:0;
}

#pg{
  align-items: center;
  text-align: center;
  margin-left: 100;
}

#Ip{
  border-radius: 20px;
  border-color: rgb(7, 142, 206);
}